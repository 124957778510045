import { visuallyHidden } from "@mui/utils";
import {
  SERVICE_CARE_PROTECTED_AREA,
  SERVICE_DEMENTIA,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  TRACK_EVENTS,
} from "core/consts";
import {
  PROVIDER_SEARCH_OVERWEIGHT,
  PSEUDO_SERVICE_OVERWEIGHT,
} from "core/model/providerSearch";
import { FilterValueServices } from "core/types";
import ConnectedChipGroup, {
  ChipGroupPresenterProps,
} from "ds_legacy/components/ChipGroup";
import RSButton from "ds_legacy/components/RSButton";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_20 } from "ds_legacy/materials/typography";
import { usePageMetaData } from "dsl/hooks";
import { useGetProviderSearchRedirectLink } from "dsl/hooks/useGetProviderSearchRedirectLink";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import {
  FormStepWrapper,
  FunnelFormData,
  FunnelSteps,
  InfoTextWithGlossaryLink,
  STEP_SERVICES,
  STEP_SOLUTIONS,
  STEP_START_DATE,
  getAccessibleTitleWithStep,
} from "../common";

export const PSEUDO_SERVICE_OTHER = 98;

const modelDefinition = convertModelDefinition({
  ...valueDef("services"),
});

const getTrackingTranslation = (service: number | undefined) => {
  switch (service) {
    case SERVICE_PALLIATIVE_CERTIFICATE:
      return "Palliative care";
    case SERVICE_DEMENTIA:
      return "Dementia care";
    case SERVICE_PSYCHIATRIC_CARE:
      return "Psychiatric care";
    case SERVICE_SPECIALISED_INTENSIVE_NURSE:
      return "Intensive care";
    default:
      return "Other";
  }
};

export function Services({
  formData,
  setCurrentStep,
  setFormData,
}: {
  formData: FunnelFormData;
  setCurrentStep: React.Dispatch<React.SetStateAction<FunnelSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<FunnelFormData>>;
}) {
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const getRedirectLink = useGetProviderSearchRedirectLink();

  usePageMetaData({
    title: (translations) =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.specialNeedsTitle,
      ),
    description: (translations) =>
      translations.providersearch.pageTitles.specialNeedsDescription,
  });

  const options: ChipGroupPresenterProps["options"] = [
    {
      name: translations.providersearch.onboardingFunnel.thirdStep.dementia,
      value: SERVICE_DEMENTIA,
    },
    {
      name: translations.providersearch.onboardingFunnel.thirdStep
        .protectedArea,
      value: SERVICE_CARE_PROTECTED_AREA,
    },
    {
      name: translations.providersearch.onboardingFunnel.thirdStep
        .psychiatricCare,
      value: SERVICE_PSYCHIATRIC_CARE,
    },
    {
      name: translations.providersearch.assessment.overweightService({
        weight: PROVIDER_SEARCH_OVERWEIGHT.toString(),
      }),
      value: PSEUDO_SERVICE_OVERWEIGHT,
    },
    {
      name: translations.providersearch.onboardingFunnel.thirdStep
        .intensiveCare,
      value: SERVICE_SPECIALISED_INTENSIVE_NURSE,
    },
    {
      name: translations.providersearch.onboardingFunnel.thirdStep.hospice,
      value: SERVICE_PALLIATIVE_CERTIFICATE,
    },
    {
      name: translations.providersearch.onboardingFunnel.answerOther,
      value: PSEUDO_SERVICE_OTHER,
    },
  ];

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={formData}
      modelDefinition={modelDefinition}
      onSubmit={({ services }: { services: FilterValueServices }) => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_SERVICE_SELECTED,
          services,
          selection: services?.map((service) =>
            getTrackingTranslation(service),
          ),
        });

        setFormData({
          ...formData,
          services,
        });
        setCurrentStep(STEP_START_DATE);
      }}
    >
      {({ submit }) => {
        return (
          <FormStepWrapper
            formData={formData}
            skipLink={getRedirectLink({
              formData: { ...formData, services: undefined },
            })}
            step={STEP_SERVICES}
            testId="services-select-wrapper"
            title={
              translations.providersearch.onboardingFunnel.thirdStep.question
            }
          >
            <ConnectedChipGroup
              chipStyle={{
                height: sizing(6),
                justifyContent: "center",
                width: "100%",
              }}
              elementName="services"
              formGroupSx={{
                flexDirection: "column",
                margin: margin(1, 0),
              }}
              formLabelSx={{
                fontSize: FONT_SIZE_20,
                margin: margin(0, 0, 2),
                ...visuallyHidden,
              }}
              options={options}
              squared
              title={getAccessibleTitleWithStep({
                step: STEP_SERVICES,
                title:
                  translations.providersearch.onboardingFunnel.thirdStep
                    .question,
                translations,
              })}
            />
            <InfoTextWithGlossaryLink funnelStep={STEP_SERVICES} />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                padding: padding(3, 0, 2),
                gap: sizing(2),
              }}
            >
              <RSButton
                buttonWrapperStyle={{
                  width: "100%",
                }}
                style={{ width: "100%", margin: margin(0) }}
                color="primary"
                id="go-back-on-services-step"
                loading="na"
                type="button"
                onClick={() => {
                  setFormData({
                    ...formData,
                    services: undefined,
                  });
                  setCurrentStep(STEP_SOLUTIONS);
                }}
                variant="outlined"
              >
                {translations.providersearch.onboardingFunnel.backButton}
              </RSButton>
              <RSButton
                buttonWrapperStyle={{
                  width: "100%",
                }}
                style={{ width: "100%", margin: margin(0) }}
                color="primary"
                id="confirm-services"
                loading="na"
                type="submit"
                onClick={submit}
                variant="contained"
              >
                {translations.providersearch.onboardingFunnel.nextButton}
              </RSButton>
            </div>
          </FormStepWrapper>
        );
      }}
    </SimpleFormRenderProps>
  );
}
